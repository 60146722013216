<template>
<div>
  <BaseInfo :baseInfo="$store.state.user.baseInfo" />
<NavBar
  title="问卷列表"
  left-arrow
  @click-left="$router.go(-1)"
/>
  <cell  title="体检报告"  is-link @click="showTJReport()"  > </cell>
  <cell  title="慢病报告"  is-link @click="showMBReport()"  > </cell>
 <RLFooter/>
</div>
</template>

<script>

import {Cell} from 'vant';

export default {
  name: 'ReportIdx',
  components: {
    Cell
  },
  data(){
    return {
      quesidxs:[],
    };
  },
  computed:{
    
  },
  methods:{
    showTJReport(){
       this.$router.push('/tjreport');
    },
    showMBReport(){
       this.$router.push('/report');
    }  
  }
,
   created() {
   
    
  },
}
</script>

<style scoped>

.is_completed{
  color: aqua;
}
.is_uncompleted{
  color:red;
}
  
</style>
